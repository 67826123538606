import Table from 'react-bootstrap/Table';
import MythicRow from './mythicRow';
export default function MythicTable(props) {
    return (
        <Table variant="dark">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>1.</th>
                    <th>2.</th>
                    <th>3.</th>
                    <th>4.</th>
                    <th>5.</th>
                    <th>6.</th>
                    <th>7.</th>
                    <th>8.</th>
                    <th>ilvl</th>
                    <th>updated</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((row, index) => {
                    return <MythicRow target={props.target} key={'row-' + index} data={{ index, ...row }} cutOff={props.cutOff} ></MythicRow>
                })}
            </tbody>
        </Table>
    )
}

