import './mythicRow.scss'
import MythicCell from './mythicCell'
export default function MythicRow(props) {
    return <tr>
        <MythicCell value={props.data.index + 1} ></MythicCell>
        <MythicCell onClick={() => { navigator.clipboard.writeText("/Rc ep " + props.data.name + " Vault 1000") }} value={props.data.name} ></MythicCell>
        <MythicCell condition={0 < props.target} value={props.data.rowData[0]} cutOff={props.cutOff} ></MythicCell>
        <MythicCell condition={1 < props.target} value={props.data.rowData[1]} cutOff={props.cutOff} ></MythicCell>
        <MythicCell condition={2 < props.target} value={props.data.rowData[2]} cutOff={props.cutOff} ></MythicCell>
        <MythicCell condition={3 < props.target} value={props.data.rowData[3]} cutOff={props.cutOff} ></MythicCell>
        <MythicCell condition={4 < props.target} value={props.data.rowData[4]} cutOff={props.cutOff} ></MythicCell>
        <MythicCell condition={5 < props.target} value={props.data.rowData[5]} cutOff={props.cutOff} ></MythicCell>
        <MythicCell condition={6 < props.target} value={props.data.rowData[6]} cutOff={props.cutOff} ></MythicCell>
        <MythicCell condition={7 < props.target} value={props.data.rowData[7]} cutOff={props.cutOff} ></MythicCell>
        <MythicCell value={props.data.gear.item_level_equipped || '-'}></MythicCell>
        <MythicCell value={props.data.last_crawled_at} ></MythicCell>
    </tr>

}
